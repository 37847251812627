<template>
  <div class="newsDetail">
    <div class="top-banner">
      <div class="title container">
        <h1>新闻资讯</h1>
        <h2>NEWS</h2>
      </div>
    </div>

    <div class="content container space">
      <h1>{{dataInfo.title}}</h1>
      <div class="time">{{dataInfo.time}}</div>
      <div class="desc" v-html="dataInfo.desc"></div>
    </div>
  </div>
</template>

<script>
import { newJson } from '@/json/news.js'
export default {
  data(){
    return {
      dataInfo: ''
    }
  },
  created(){
    let idx = this.$route.query.idx;
    this.dataInfo = newJson[idx];
  },

  methods: {
    
  }
}
</script>
<style lang="scss">
.newsDetail {
  .content {
    // max-width: 1024px;
    min-height: 500px;
    // margin: 50px auto;
    h1 {
      font-weight: bold;
    }
    .time {
      color:rgba(51,51,51,.3);
      margin: 30px 0;
    }
    .desc {
      line-height: 30px;
      p {
        margin: 20px 0;
        text-indent:32px
      }
      img {
        width: auto;
        max-width: 100%;
        margin: 0px auto;
        display: block;

      }
    }
  }
}
@media (max-width: 768px){ 
  .newsDetail {
    .content {
      // padding: 0 20px;
      h1 {
        font-size: 16px;
      }
      .time {
        margin-top: 20px;
      }
    }
  }
}
</style>
